import Q from 'q';
// 免审设置列表
import consumer_web_staff_pageList from '@/lib/data-service/haolv-default/consumer_web_staff_pageList';
//  更新免审设置类型
import consumer_web_staff_updateFreeType from '@/lib/data-service/haolv-default/consumer_web_staff_updateFreeType';
//  请求员工数据
import consumer_department_getDepartmentUsers from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers';
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList'
import consumer_web_staff_staffBookingAuthInfo from '@/lib/data-service/haolv-default/consumer_web_staff_staffBookingAuthInfo'
import consumer_web_staff_editStaffBookingAuth from '@/lib/data-service/haolv-default/consumer_web_staff_editStaffBookingAuth'

import Transfer from '@/component/transfer/1.0.0/index.vue';
export default {
  components: {
    Transfer,
  },
  data() {
    var validateIds = (rule, value, callback) => {
      if (value === '' || value === undefined || !value.length) {
        callback(new Error('请选择员工'));
      } else {
        callback();
      }
    };

    var validateFreeApproveType = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('请选择免审类型'));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      deptLoading: false,
      exemptVisible: false,
      roleVisible: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        staffName: '',
        freeApproverType: '-1',
        keyword: '',
        companyId: null,   // 公司id，前端不传
        deptIds: null,   // 部门ids
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      list: [],
      exemptSetForm: {
        ids: [],
        freeApproveType: '',
      },
      exemptSetRules: {
        freeApproveType: [
          { required: true, message: '请选择免审类型', trigger: 'blur' },
        ],
      },
      freeApproveType: [
        { label: '1', text: '个免' },
        { label: '2', text: '预定免审' },
      ],
      roleForm: {
        ids: [],
        freeApproveType: '',
      },
      roleRules: {
        // 非必选
        ids: [{ required: true, validator: validateIds, trigger: 'blur' }],
        freeApproveType: [
          {
            required: true,
            validator: validateFreeApproveType,
            trigger: 'blur',
          },
        ],
      },
      treeData: [],
      treeData2: [],
      defaultProps: {
        children: 'userList',
        label: 'deptName',
      },
      searchName: '',

      ruleVisible: false,
      deptList: [],
      props: {
        label: 'deptName',
        children: 'childrenList',
        disabled: 'disabled'
      },
      ruleForm: {
        bookingAuthType: 1, // 预定免审权限 1：不限 2：按部门
        userId: '',
        deptIds: [],
        specifyUserIds: [],
      },
      ruleRules: {
        bookingAuthType: [
          { required: true, message: '请选择权限', trigger: 'blur' },
        ],
        specifyUserIds: [{ required: true, validator: validateIds, trigger: 'blur' }],
      },
      ruleLoading: false,
      ruleCanSubmit: true,
      activeDeptList: [],

      canAddNew: true,
    };
  },
  computed: {
    activeDeptListTree() {
      let _result = [];
      let _currentId = [];
      let _parentId = [];
      let _childId = [];
      function _getChildId(val) {
        if (val.childrenList && val.childrenList.length > 0) {
          val.childrenList.forEach(value=>{
            _childId.push(value.deptId);
            _getChildId(value);
          })
        }
      }
      this.activeDeptList.forEach(value => {
        // 先把所有子节点保存
        _getChildId(value);
        if (value.parentId === 0) {
          _parentId.push(value.deptId);
          _result.push(value);
        } else {
          _currentId.push(value)
        }
      });
      _currentId.forEach(value => {
        if (_parentId.indexOf(value.parentId) === -1 && _childId.indexOf(value.deptId) === -1) {
          _result.push(value);
        }
      })
      console.log('res', _result);
      return _result;
    }
  },
  methods: {
    indexMethod (index) {
      return (this.form.currentPage - 1) * 10 + index + 1;
    },
    req_list() {
      const _this = this;
      _this.loading = true;
      _this.list = [];
      Q.when()
        .then(() => {
          return consumer_web_staff_pageList(this.form)
        })
        .then((res) => {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount;
          _this.loading = false;
        });
    },
    req_staff_list() {
      return Q.when().then(function() {
        return consumer_department_getDepartmentUsers();
      });
    },
    // 更新和添加
    req_change(params) {
      return Q.when().then(function() {
        return consumer_web_staff_updateFreeType(params);
      });
    },
    search() {
      this.form.currentPage = 1;
      this.req_list();
    },
    change_search (val) {
      if (!val) {
        this.search();
      }
    },
    get_staff() {
      const _this = this;
      this.deptLoading = true;
      Q.when()
          .then(function() {
            return _this.req_staff_list();
          })
          .then(function(res) {
            const list = res.datas;
            let formatData = (datas) => {
              const list = datas.map((data) => {
                let obj = {};
                obj.deptId = data.deptId;
                obj.deptName = data.deptName;
                obj.children = [];
                obj.userList = [];
                obj.children = formatData(data.deptList);
                obj.userList = data.staffList.map((staff) => {
                  return { id: staff.subStaffId, name: staff.subStaffName };
                });
                return obj;
              });
              return list;
            };
            let formatData2 = (datas) => {
              const list = datas.map((data) => {
                let obj = {};
                obj.deptId = data.deptId;
                obj.deptName = data.deptName;
                obj.children = [];
                obj.userList = [];
                obj.children = formatData(data.deptList);
                obj.userList = data.staffList.map((staff) => {
                  return { id: staff.subUserId, name: staff.subStaffName };
                });
                return obj;
              });
              return list;
            };
            _this.treeData = formatData(list);
            _this.treeData2 = formatData2(list);
            _this.deptLoading = false;
          }).catch(() =>{
        _this.deptLoading = false;
      });
    },
    popup_staff() {
      const _this = this;
      _this.roleVisible = true;
      this.$nextTick(()=>{
        this.$refs.transfer.reactTransfer();
      })
    },
    batch_set() {
      if (this.exemptSetForm.ids.length) {
        this.exemptVisible = true;
      } else {
        this.$confirm('请选择需要设置的数据', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    batch_delete() {
      if (this.exemptSetForm.ids.length) {
        this.$confirm(`是否确认批量删除`, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const _this = this;
            Q.when()
              .then(function() {
                _this.exemptSetForm.freeApproveType = '0';
                return _this.req_change(_this.exemptSetForm);
              })
              .then(function(res) {
                _this.$message.success('删除成功');
                _this.req_list();
              });
          })
          .catch(() => {});
      } else {
        this.$confirm('请选择需要设置的数据', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    commit_exempt_set() {
      this.$refs.exemptSetForm.validate((valid) => {
        if (valid) {
          const _this = this;
          Q.when()
            .then(function() {
              _this.req_change(_this.exemptSetForm);
            })
            .then(function(res) {
              _this.close_exempt();
              _this.$message.success('修改成功');
              setTimeout(function() {
                _this.req_list();
              }, 300);
            });
        } else {
          return false;
        }
      });
    },
    close_exempt() {
      this.exemptVisible = false;
      this.exemptSetForm = {
        ids: [],
        freeApproveType: '',
      };
      this.$refs.exemptSetForm.clearValidate();
    },
    close_roleDialog() {
      this.roleVisible = false;
      this.$refs.roleForm.clearValidate();
      this.$refs.roleForm.resetFields();
      this.roleForm.ids = [];
    },
    commit_role() {

      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          const _this = this;
          if (!this.canAddNew) {

          }
          this.canAddNew = false;
          Q.when()
            .then(function() {
              return _this.req_change(_this.roleForm);
            })
            .then(function(res) {
              _this.canAddNew = false;
              _this.close_roleDialog();
              _this.$message.success('添加成功');
              _this.req_list();
              _this.roleForm.ids = [];
            })
            .catch(function (rej) {
              _this.canAddNew = false;
              _this.roleForm.ids = [];
            })
        } else {
          return false;
        }
      });
    },
    selectionChange(val) {
      const ids = val.map(function(item) {
        return item.staffId;
      });
      this.exemptSetForm.ids = ids;
    },
    popup_set(row) {
      this.exemptSetForm = {
        ids: [row.staffId],
        freeApproveType: row.freeApproverType.toString(),
      };
      this.exemptVisible = true;
    },
    delete_exempt(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const _this = this;
          _this.loading = true;
          _this.req_change({ ids: [id], freeApproveType: '0' }).then(res=>{
            _this.loading = false;
            _this.$message.success('删除成功');
            _this.req_list();
          })
        })
        .catch(() => {
          _this.loading = false;
        });
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list();
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },

    close_ruleDialog() {
      this.ruleVisible = false;
    },

    popup_rule_set(val) {
      this.ruleVisible = true;
      this.ruleLoading = true;
      this.ruleForm = {
        bookingAuthType: 1, // 预定免审权限 1：不限 2：按部门
        userId: val.userId,
        deptIds: [],
        specifyUserIds: [],
      }
      consumer_web_staff_staffBookingAuthInfo({userId: val.userId}).then(res => {
        this.ruleForm.bookingAuthType = res.datas.bookingAuthType;
        this.ruleForm.deptIds = res.datas.deptIds || [];
        this.ruleForm.specifyUserIds = res.datas.specifyUserIds || [];
        this.$nextTick(()=>{
          if ([1,2].indexOf(this.ruleForm.bookingAuthType) > -1) {
            this.$refs.tree.setCheckedKeys(res.datas.deptIds || []);
          }
          if (this.ruleForm.bookingAuthType === 1) {
            this._changeDept(this.ruleForm.bookingAuthType);
          }

          /*this.$nextTick(()=>{
            let aa = this.$refs.tree.getCheckedNodes();
            console.log(aa);
          })*/
        })
      }).finally(()=>{
        this.ruleLoading = false;
      });


    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      let deptList = this.$refs.tree.getCheckedNodes();
      this.activeDeptList = deptList;
      console.log('aa', deptList);
      let deptIds = [];
      deptList.forEach(value=>{
        deptIds.push(value.deptId)
      })
      this.ruleForm.deptIds = deptIds;
    },
    _checkRuleForm() {
      return new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid);
        })
      })
    },
    async commit_rule() {
      let checkRes = await this._checkRuleForm();
      console.log('checkRes', checkRes)
      if (!checkRes) {
          return
      }
      if (!this.ruleCanSubmit) {
          return;
      }
      this.ruleCanSubmit = false;
      this.ruleLoading = true;
      consumer_web_staff_editStaffBookingAuth(this.ruleForm).then(res => {
        this.$message({type: "success", message: '修改成功'});
        this.ruleVisible = false;
        this.req_list();
      }).finally(()=>{
        this.ruleCanSubmit = true;
        this.ruleLoading = false;
      })
    },

    getDeptList() {
      consumer_department_getDepartmentList().then(res=>{
        let deptList = res.datas.departmentListResults;
        this.deptList = deptList;
      });
    },

    clearActiveNode(val) {
      if (this.ruleForm.bookingAuthType === 1) {
          return
      }
      this.$refs.tree.setChecked(val.deptId, false);
      this.$nextTick(()=>{
        let deptList = this.$refs.tree.getCheckedNodes();
        this.activeDeptList = deptList;
        console.log('aa', deptList);
        let deptIds = [];
        deptList.forEach(value=>{
          deptIds.push(value.deptId)
        })
        this.ruleForm.deptIds = deptIds;
      })
    },
    _checkDeptIds(rule, value, callback, ruleForm) {
      if (ruleForm.bookingAuthType === 2) {
          if (ruleForm.deptIds.length > 0) {
            callback();
          } else {
            callback(new Error('请选择可管理部门'))
          }
      } else {
        callback()
      }
    },

    _changeDept(val) {
      console.log(val)
      let deptList = JSON.parse(JSON.stringify(this.deptList))
      if (val === 1) {
          // 不限
        let allId = [];
        function allDisabled(val) {
          val.forEach(value => {
            allId.push(value.deptId);
            value.disabled = true;
            if (value.childrenList) {
              allDisabled(value.childrenList)
            }
          })
        }
        allDisabled(deptList);
        console.log('allId', allId);
        this.$refs.tree.setCheckedKeys(allId);
      } else {
        function allDisabled(val) {
          val.forEach(value => {
            value.disabled = false;
            if (value.childrenList) {
              allDisabled(value.childrenList)
            }
          })
        }
        allDisabled(deptList);
        this.$refs.tree.setCheckedKeys([]);
      }
      this.deptList = deptList;
      this.$forceUpdate();

    }
  },
  mounted() {

  },
  activated() {
    this.get_staff();
    this.getDeptList();
    this.req_list();
  },
  deactivated() {},
};
