
// 更新免审设置类型接口文档地址：http://192.168.0.12:8765/doc.html#/haolv-consumer/t-us-staff-controller/updateFreeTypeUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_staff_updateFreeType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/staff/updateFreeType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_staff_updateFreeType;